import Client from './client'

const EVENT_TYPE = 'hosted_document'
const PUT_URL = 'v1/snippets/websites/:websiteUUID/statistics'


export default function postStatistics({ policyUUID, websiteUUID }) {
  const url = PUT_URL.replace(':websiteUUID', websiteUUID)

  return Client.post(url, {
    document_uuid: policyUUID,
    event_type: EVENT_TYPE,
    website_uuid: websiteUUID,
  })
}
