import Client from '../client'

const GET_URL = 'v1/consumer/policies/:policyUUID'


export default function getPolicyMetadata({ policyUUID }) {
  const url = GET_URL
    .replace(':policyUUID', policyUUID)

  return Client.get(url)
}
