import hostedPolicy from './hosted-policy'


(function() {
  const params = new URLSearchParams(window.location.search)

  const policyUUID = params.get('policyUUID') || getPolicyUUID(window.location)

  try {
    hostedPolicy({
      policyUUID,
    })
  } catch (error) {
    console.error('ERROR: %o', error)
    window.location.assign('/user/policy-not-found')
  }
})()

// For legacy URL handling
//
function getPolicyUUID({ pathname }) {
  const urlArray = pathname.split('/')

  // The alternation handles the case where we have a trailing slash.
  // E.g.:
  //   * /foo/uuid => ['', 'foo', 'uuid']
  //   * /foo/uuid/ => ['', 'foo', 'uuid', '']
  return urlArray[urlArray.length - 1] || urlArray[urlArray.length - 2]
}
