import { Client } from '@termly_web/common'


export default {
  ...Client,
  defaults: {
    ...Client.defaults,
    baseURL: `${ process.env.DOMAIN }/api`,
  },
}
