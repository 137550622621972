import Client from './client'

const GET_URL = 'v1/consumer/policies/:policyUUID'


export default async function getPolicyMetadata({ policyUUID }) {
  const url = GET_URL
    .replace(':policyUUID', policyUUID)

  const { data } = await Client.get(url)

  return data
}
