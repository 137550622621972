import Client from './client'

const GET_URL = 'v1/snippets/documents/:policyUUID/website'


export default async function getWebsiteUUID({ policyUUID }) {
  const url = GET_URL.replace(':policyUUID', policyUUID)

  const { data } = await Client.get(url)

  return data.uuid
}
