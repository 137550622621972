import 'iframe-resizer/js/iframeResizer.contentWindow'
import 'url-polyfill'
import 'url-search-params-polyfill'

import { selectLocale } from '@termly_web/common'

import getPolicyContent from './lib/getPolicyContent'
import getPolicyMetadata from './lib/getPolicyMetadata'
import getWebsiteUUID from './lib/getWebsiteUUID'
import postStatistics from './lib/postStatistics'


export default async function hostedPolicy({ policyUUID }) {
  const websiteUUID = await getWebsiteUUID({
    policyUUID,
  })

  const {
    created_locales: supportedLocales,
    id: documentID,
    total_published_count: totalPublishedCount,
    uuid: documentUUID,
  } = await getPolicyMetadata({
    policyUUID,
    websiteUUID,
  })

  if ( totalPublishedCount === 0 ) {
    throw new Error('No published policy was found')
  }

  const locale = selectLocale({
    supportedLocales,
    preferredLocales: window.navigator.languages,
  })

  try {
    postStatistics({
      policyUUID: documentUUID,
      websiteUUID,
    })
  } catch (error) {
    console.warn('Failed to post statistics: %o', error)
  }

  const content = await getPolicyContent({
    documentID,
    locale,
    policyUUID,
    websiteUUID,
  })

  const docDiv = document.createElement('div')
  docDiv.innerHTML = content

  // This is used by iframe-resizer to calculate the height of the <iframe> in which
  // this content will be displayed.
  docDiv.dataset.iframeHeight = true

  document.getElementById('hosted').appendChild(docDiv)
}
